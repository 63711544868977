var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-coefficient-form" },
    [
      _c(
        "a-row",
        {
          staticClass: "form-item-row",
          attrs: { type: "flex", align: "middle" },
        },
        [
          _c(
            "a-col",
            _vm._b({}, "a-col", _vm.calcTotalCol, false),
            [
              _c(
                "a-row",
                { attrs: { type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { span: 5 } }, [_vm._v("公式")]),
                  _c(
                    "a-col",
                    { staticClass: "gutter-row", attrs: { flex: "1" } },
                    [
                      _vm.isEdit
                        ? [
                            _c("a-textarea", {
                              model: {
                                value: _vm.formData.formula,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "formula", $$v)
                                },
                                expression: "formData.formula",
                              },
                            }),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "white-space": "pre-line",
                                  "word-break": "break-word",
                                },
                              },
                              [_vm._v(_vm._s(_vm.formData.formula))]
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.ruleList.includes("DIFFICULTLY")
        ? _c(
            "a-form-model",
            [
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 20 } }, [
                    _c("div", { staticClass: "rule-title" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "inline-block",
                            width: "35px",
                          },
                        },
                        [
                          _c("span", {
                            staticClass:
                              "ant-badge-status-dot ant-badge-status-processing",
                          }),
                        ]
                      ),
                      _c("span", [_vm._v("难度系数规则")]),
                    ]),
                  ]),
                  _c("a-col", { attrs: { span: 4 } }, [
                    _vm.isEdit
                      ? _c(
                          "div",
                          { staticClass: "card-remove" },
                          [
                            _c("a-button", {
                              staticStyle: { position: "relative" },
                              attrs: {
                                shape: "circle",
                                size: "small",
                                icon: "close",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteCard("DIFFICULTLY")
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._l(_vm.formData.difficulties, function (formItem, formIndex) {
                return _c(
                  "div",
                  {
                    key: formItem.rowKey || formIndex,
                    staticClass: "difficulty-type",
                  },
                  [
                    _c(
                      "a-form-model-item",
                      { staticClass: "type-title" },
                      [
                        _c(
                          "a-row",
                          _vm._b(
                            { attrs: { type: "flex", align: "middle" } },
                            "a-row",
                            _vm.flex,
                            false
                          ),
                          [
                            _vm.isOnlyCoefficient
                              ? [
                                  _c(
                                    "a-col",
                                    _vm._b(
                                      {
                                        staticClass:
                                          "form-title-item type-title-preview",
                                      },
                                      "a-col",
                                      _vm.calcTotalCol,
                                      false
                                    ),
                                    [
                                      _vm.isEdit
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "35px",
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    `${
                                                      _vm.numbers[formIndex] ||
                                                      formIndex + 1
                                                    }、`
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isEdit
                                        ? _c(
                                            "a-tree-select",
                                            {
                                              ref: "selectTree",
                                              refInFor: true,
                                              staticStyle: {
                                                width: "calc(100% - 35px)",
                                                "font-size": "16px",
                                              },
                                              attrs: {
                                                placeholder: "请选择大类",
                                              },
                                              on: {
                                                change: (value) => {
                                                  _vm.factorChange(
                                                    value,
                                                    formItem
                                                  )
                                                },
                                              },
                                              model: {
                                                value: formItem.title,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    formItem,
                                                    "title",
                                                    $$v
                                                  )
                                                },
                                                expression: "formItem.title",
                                              },
                                            },
                                            _vm._l(
                                              _vm.treeDatas,
                                              function (item) {
                                                return _c(
                                                  "a-tree-select-node",
                                                  {
                                                    key: item.bigType,
                                                    attrs: {
                                                      value: item.bigType,
                                                      title: item.bigType,
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.children,
                                                    function (data) {
                                                      return _c(
                                                        "a-tree-select-node",
                                                        {
                                                          key: data.id,
                                                          attrs: {
                                                            value: data.id,
                                                            title:
                                                              data.smallType,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "35px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      `${
                                                        _vm.numbers[
                                                          formIndex
                                                        ] || formIndex + 1
                                                      }、`
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    `${formItem.title || ""}`
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    _vm._b({}, "a-col", _vm.col[2], false),
                                    [
                                      _c(
                                        "a-row",
                                        _vm._b(
                                          { staticClass: "row-option" },
                                          "a-row",
                                          _vm.flex,
                                          false
                                        ),
                                        [
                                          _c(
                                            "a-col",
                                            [
                                              _vm.isEdit
                                                ? _c("a-button", {
                                                    attrs: {
                                                      shape: "circle",
                                                      icon: "minus",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delItem(
                                                          formIndex
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            [
                                              _vm.isEdit
                                                ? _c("a-button", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.formData
                                                          .difficulties.length >
                                                        9,
                                                      shape: "circle",
                                                      icon: "plus",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addItem(
                                                          formIndex
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c(
                                    "a-col",
                                    _vm._b(
                                      {
                                        staticClass:
                                          "form-title-item type-title-preview",
                                      },
                                      "a-col",
                                      _vm.col[0],
                                      false
                                    ),
                                    [
                                      _vm.isEdit
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "35px",
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    `${
                                                      _vm.numbers[formIndex] ||
                                                      formIndex + 1
                                                    }、`
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isEdit
                                        ? _c(
                                            "a-select",
                                            {
                                              staticStyle: {
                                                width: "calc(100% - 35px)",
                                                "font-size": "16px",
                                              },
                                              attrs: {
                                                placeholder: "请选择大类",
                                              },
                                              on: {
                                                change: (value) => {
                                                  _vm.factorChange(
                                                    value,
                                                    formItem
                                                  )
                                                },
                                              },
                                              model: {
                                                value: formItem.title,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    formItem,
                                                    "title",
                                                    $$v
                                                  )
                                                },
                                                expression: "formItem.title",
                                              },
                                            },
                                            _vm._l(
                                              _vm.treeData,
                                              function (item) {
                                                return _c(
                                                  "a-select-option",
                                                  {
                                                    key: item.bigType,
                                                    attrs: {
                                                      value: item.bigType,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.bigType) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "35px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      `${
                                                        _vm.numbers[
                                                          formIndex
                                                        ] || formIndex + 1
                                                      }、`
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    `${formItem.title || ""}`
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    _vm._b({}, "a-col", _vm.col[1], false),
                                    [
                                      _vm.isEdit
                                        ? _c("a-input", {
                                            attrs: {
                                              addonBefore: "权重",
                                              suffix: "%",
                                            },
                                            model: {
                                              value: formItem.weight,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  formItem,
                                                  "weight",
                                                  $$v
                                                )
                                              },
                                              expression: "formItem.weight",
                                            },
                                          })
                                        : _c("div", {
                                            domProps: {
                                              textContent: _vm._s(
                                                `权重：${
                                                  formItem.weight || ""
                                                }%`
                                              ),
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    _vm._b({}, "a-col", _vm.col[2], false),
                                    [
                                      _c(
                                        "a-row",
                                        _vm._b(
                                          { staticClass: "row-option" },
                                          "a-row",
                                          _vm.flex,
                                          false
                                        ),
                                        [
                                          _c(
                                            "a-col",
                                            [
                                              _vm.isEdit
                                                ? _c("a-button", {
                                                    attrs: {
                                                      shape: "circle",
                                                      icon: "minus",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delItem(
                                                          formIndex
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            [
                                              _vm.isEdit
                                                ? _c("a-button", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.formData
                                                          .difficulties.length >
                                                        9,
                                                      shape: "circle",
                                                      icon: "plus",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addItem(
                                                          formIndex
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._l(
                      formItem.categories,
                      function (formChild, formChildIndex) {
                        return _c(
                          "div",
                          {
                            key: formChild.rowKey || formChildIndex,
                            staticClass: "category-title",
                          },
                          [
                            _c(
                              "a-form-model-item",
                              [
                                _c(
                                  "a-row",
                                  _vm._b(
                                    {
                                      attrs: { type: "flex", align: "middle" },
                                    },
                                    "a-row",
                                    _vm.flex,
                                    false
                                  ),
                                  [
                                    _c(
                                      "a-col",
                                      _vm._b(
                                        {
                                          staticClass: "category-title-preview",
                                        },
                                        "a-col",
                                        _vm.col[0],
                                        false
                                      ),
                                      [
                                        _vm.isEdit
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "35px",
                                                  display: "inline-block",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      `${formIndex + 1}.${
                                                        formChildIndex + 1
                                                      }`
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.isEdit
                                          ? _c(
                                              "a-select",
                                              {
                                                staticStyle: {
                                                  width: "calc(100% - 35px)",
                                                },
                                                attrs: {
                                                  placeholder: "请选择小类",
                                                },
                                                model: {
                                                  value: formChild.factorId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      formChild,
                                                      "factorId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formChild.factorId",
                                                },
                                              },
                                              _vm._l(
                                                formItem.factors,
                                                function (item) {
                                                  return _c(
                                                    "a-select-option",
                                                    {
                                                      key: item.id,
                                                      attrs: { value: item.id },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.smallType) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "35px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        `${formIndex + 1}.${
                                                          formChildIndex + 1
                                                        }`
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getFactorTitle(
                                                        formChild.factorId,
                                                        _vm.treeData
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      _vm._b({}, "a-col", _vm.col[1], false),
                                      [
                                        _vm.isEdit
                                          ? _c(
                                              "a-select",
                                              {
                                                model: {
                                                  value: formChild.type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      formChild,
                                                      "type",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "formChild.type",
                                                },
                                              },
                                              _vm._l(
                                                [
                                                  {
                                                    label: "文本",
                                                    value: "INPUT",
                                                  },
                                                  {
                                                    label: "数值范围",
                                                    value: "RANGE",
                                                  },
                                                ],
                                                function (item) {
                                                  return _c(
                                                    "a-select-option",
                                                    {
                                                      key: item.value,
                                                      attrs: {
                                                        value: item.value,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.label) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : [
                                              _vm._v(
                                                _vm._s(
                                                  formChild.type == "INPUT"
                                                    ? "文本"
                                                    : "数值范围"
                                                )
                                              ),
                                            ],
                                      ],
                                      2
                                    ),
                                    _c(
                                      "a-col",
                                      _vm._b({}, "a-col", _vm.col[2], false),
                                      [
                                        _c(
                                          "a-row",
                                          _vm._b(
                                            { staticClass: "row-option" },
                                            "a-row",
                                            _vm.flex,
                                            false
                                          ),
                                          [
                                            _c(
                                              "a-col",
                                              [
                                                _vm.isEdit
                                                  ? _c("a-button", {
                                                      attrs: {
                                                        shape: "circle",
                                                        icon: "minus",
                                                        size: "small",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delItem(
                                                            formIndex,
                                                            formChildIndex
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              [
                                                _vm.isEdit
                                                  ? _c("a-button", {
                                                      attrs: {
                                                        shape: "circle",
                                                        icon: "plus",
                                                        size: "small",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addItem(
                                                            formIndex,
                                                            formChildIndex
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              formChild.options,
                              function (option, optionIndex) {
                                return _c(
                                  "a-form-model-item",
                                  { key: option.rowKey || optionIndex },
                                  [
                                    _c(
                                      "a-row",
                                      _vm._b(
                                        {
                                          attrs: {
                                            type: "flex",
                                            align: "middle",
                                          },
                                        },
                                        "a-row",
                                        _vm.flex,
                                        false
                                      ),
                                      [
                                        _c(
                                          "a-col",
                                          _vm._b(
                                            {},
                                            "a-col",
                                            _vm.col[0],
                                            false
                                          ),
                                          [
                                            _vm.isEdit
                                              ? _c("div", {
                                                  staticStyle: {
                                                    width: "35px",
                                                    display: "inline-block",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      optionIndex < 26
                                                        ? String.fromCharCode(
                                                            65 + optionIndex
                                                          )
                                                        : optionIndex
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.isEdit
                                              ? [
                                                  formChild.type == "RANGE"
                                                    ? _c("edit-range-select", {
                                                        staticStyle: {
                                                          width:
                                                            "calc(100% - 35px)",
                                                          float: "right",
                                                          "margin-top": "3px",
                                                        },
                                                        attrs: {
                                                          formData:
                                                            option.range,
                                                          rangeKey: "type",
                                                          valueKey: "value",
                                                          unitKey: "unit",
                                                        },
                                                      })
                                                    : _c("a-input", {
                                                        staticStyle: {
                                                          width:
                                                            "calc(100% - 35px)",
                                                          display:
                                                            "inline-block",
                                                        },
                                                        attrs: {
                                                          placeholder: "选项",
                                                        },
                                                        model: {
                                                          value: option.title,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              option,
                                                              "title",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "option.title",
                                                        },
                                                      }),
                                                ]
                                              : [
                                                  formChild.type == "INPUT"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticStyle: {
                                                              width: "35px",
                                                            },
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  optionIndex <
                                                                    26
                                                                    ? String.fromCharCode(
                                                                        65 +
                                                                          optionIndex
                                                                      )
                                                                    : optionIndex
                                                                ),
                                                            },
                                                          }),
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                `${
                                                                  option.title ||
                                                                  ""
                                                                }`
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticStyle: {
                                                              width: "35px",
                                                            },
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  optionIndex <
                                                                    26
                                                                    ? String.fromCharCode(
                                                                        65 +
                                                                          optionIndex
                                                                      )
                                                                    : optionIndex
                                                                ),
                                                            },
                                                          }),
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                option.range
                                                                  ? _vm.getCalcRange(
                                                                      option.range
                                                                    )
                                                                  : option.range
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                ],
                                          ],
                                          2
                                        ),
                                        _c(
                                          "a-col",
                                          _vm._b(
                                            {},
                                            "a-col",
                                            _vm.col[1],
                                            false
                                          ),
                                          [
                                            _vm.isEdit
                                              ? _c("a-input-number", {
                                                  staticClass: "input-factor",
                                                  attrs: {
                                                    placeholder: "系数",
                                                  },
                                                  model: {
                                                    value: option.factor,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        option,
                                                        "factor",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "option.factor",
                                                  },
                                                })
                                              : _c("div", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      option.factor === false
                                                        ? ""
                                                        : option.factor
                                                    ),
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a-col",
                                          _vm._b(
                                            {},
                                            "a-col",
                                            _vm.col[2],
                                            false
                                          ),
                                          [
                                            _c(
                                              "a-row",
                                              _vm._b(
                                                { staticClass: "row-option" },
                                                "a-row",
                                                _vm.flex,
                                                false
                                              ),
                                              [
                                                _c(
                                                  "a-col",
                                                  [
                                                    _vm.isEdit
                                                      ? _c("a-button", {
                                                          attrs: {
                                                            shape: "circle",
                                                            icon: "minus",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.delItem(
                                                                formIndex,
                                                                formChildIndex,
                                                                optionIndex
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "a-col",
                                                  [
                                                    _vm.isEdit
                                                      ? _c("a-button", {
                                                          attrs: {
                                                            disabled:
                                                              formChild.options
                                                                .length > 25,
                                                            shape: "circle",
                                                            icon: "plus",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addItem(
                                                                formIndex,
                                                                formChildIndex,
                                                                optionIndex
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      }
                    ),
                  ],
                  2
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.ruleList.includes("FACTOR")
        ? _c(
            "a-form-model",
            [
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 20 } }, [
                    _c("div", { staticClass: "rule-title" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "inline-block",
                            width: "35px",
                          },
                        },
                        [
                          _c("span", {
                            staticClass:
                              "ant-badge-status-dot ant-badge-status-processing",
                          }),
                        ]
                      ),
                      _c("span", [_vm._v("单拎系数规则")]),
                    ]),
                  ]),
                  _c("a-col", { attrs: { span: 4 } }, [
                    _vm.isEdit
                      ? _c(
                          "div",
                          { staticClass: "card-remove" },
                          [
                            _c("a-button", {
                              staticStyle: { position: "relative" },
                              attrs: {
                                shape: "circle",
                                size: "small",
                                icon: "close",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteCard("FACTOR")
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._l(_vm.formData.factors, function (formItem, formIndex) {
                return _c(
                  "div",
                  {
                    key: formItem.rowKey || formIndex,
                    staticClass: "difficulty-type",
                  },
                  [
                    _c(
                      "a-form-model-item",
                      { staticClass: "type-title" },
                      [
                        _c(
                          "a-row",
                          _vm._b(
                            { attrs: { type: "flex", align: "middle" } },
                            "a-row",
                            _vm.flex,
                            false
                          ),
                          [
                            _vm.isOnlyCoefficient
                              ? [
                                  _c(
                                    "a-col",
                                    _vm._b(
                                      {
                                        staticClass:
                                          "form-title-item type-title-preview",
                                      },
                                      "a-col",
                                      _vm.calcTotalCol,
                                      false
                                    ),
                                    [
                                      _vm.isEdit
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "35px",
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    `${
                                                      _vm.numbers[formIndex] ||
                                                      formIndex + 1
                                                    }、`
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isEdit
                                        ? _c("a-input", {
                                            staticStyle: {
                                              "font-weight": "bold",
                                              "font-size": "16px",
                                            },
                                            attrs: {
                                              disabled:
                                                formItem.title == "急稿系数",
                                              placeholder: "请输入系数名称",
                                            },
                                            model: {
                                              value: formItem.title,
                                              callback: function ($$v) {
                                                _vm.$set(formItem, "title", $$v)
                                              },
                                              expression: "formItem.title",
                                            },
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "35px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      `${
                                                        _vm.numbers[
                                                          formIndex
                                                        ] || formIndex + 1
                                                      }、`
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    `${formItem.title || ""}`
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    _vm._b({}, "a-col", _vm.col[2], false),
                                    [
                                      _c(
                                        "a-row",
                                        _vm._b(
                                          { staticClass: "row-option" },
                                          "a-row",
                                          _vm.flex,
                                          false
                                        ),
                                        [
                                          _c(
                                            "a-col",
                                            [
                                              _vm.isEdit
                                                ? _c("a-button", {
                                                    attrs: {
                                                      shape: "circle",
                                                      icon: "minus",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delItems(
                                                          formIndex
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            [
                                              _vm.isEdit
                                                ? _c("a-button", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.formData
                                                          .difficulties.length >
                                                        9,
                                                      shape: "circle",
                                                      icon: "plus",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addItems(
                                                          formIndex
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c(
                                    "a-col",
                                    {
                                      staticClass:
                                        "form-title-item type-title-preview",
                                      attrs: { span: 20 },
                                    },
                                    [
                                      _vm.isEdit
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "35px",
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    `${
                                                      _vm.numbers[formIndex] ||
                                                      formIndex + 1
                                                    }、`
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isEdit
                                        ? _c("a-input", {
                                            staticStyle: {
                                              "font-weight": "bold",
                                              "font-size": "16px",
                                            },
                                            attrs: {
                                              disabled:
                                                formItem.title == "急稿系数",
                                              placeholder: "请输入系数名称",
                                            },
                                            model: {
                                              value: formItem.title,
                                              callback: function ($$v) {
                                                _vm.$set(formItem, "title", $$v)
                                              },
                                              expression: "formItem.title",
                                            },
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "35px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      `${
                                                        _vm.numbers[
                                                          formIndex
                                                        ] || formIndex + 1
                                                      }、`
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    `${formItem.title || ""}`
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    _vm._b({}, "a-col", _vm.col[2], false),
                                    [
                                      _c(
                                        "a-row",
                                        _vm._b(
                                          { staticClass: "row-option" },
                                          "a-row",
                                          _vm.flex,
                                          false
                                        ),
                                        [
                                          _c(
                                            "a-col",
                                            [
                                              _vm.isEdit
                                                ? _c("a-button", {
                                                    attrs: {
                                                      shape: "circle",
                                                      icon: "minus",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delItems(
                                                          formIndex
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            [
                                              _vm.isEdit
                                                ? _c("a-button", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.formData
                                                          .difficulties.length >
                                                        9,
                                                      shape: "circle",
                                                      icon: "plus",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addItems(
                                                          formIndex
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._l(
                      formItem.categories,
                      function (formChild, formChildIndex) {
                        return _c(
                          "div",
                          {
                            key: formChild.rowKey || formChildIndex,
                            staticClass: "category-title",
                          },
                          [
                            _c(
                              "a-form-model-item",
                              [
                                _vm.isEdit
                                  ? _c(
                                      "a-row",
                                      _vm._b(
                                        {
                                          attrs: {
                                            type: "flex",
                                            align: "middle",
                                          },
                                        },
                                        "a-row",
                                        _vm.flex,
                                        false
                                      ),
                                      [
                                        _c(
                                          "a-col",
                                          _vm._b(
                                            {
                                              staticClass:
                                                "category-title-preview",
                                            },
                                            "a-col",
                                            _vm.col[0],
                                            false
                                          ),
                                          [
                                            _vm.isEdit
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "35px",
                                                      display: "inline-block",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          `${formIndex + 1}.${
                                                            formChildIndex + 1
                                                          }`
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.isEdit
                                              ? _c(
                                                  "a-tree-select",
                                                  {
                                                    ref: "selectTree",
                                                    refInFor: true,
                                                    staticStyle: {
                                                      width:
                                                        "calc(100% - 35px)",
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        formItem.title ==
                                                        "急稿系数"
                                                          ? "急稿"
                                                          : "请选择取值目标",
                                                      disabled:
                                                        formItem.title ==
                                                        "急稿系数",
                                                    },
                                                    on: {
                                                      change: (value) => {
                                                        _vm.treeSelectChange(
                                                          value,
                                                          formChild,
                                                          formItem
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: formChild.factorId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          formChild,
                                                          "factorId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formChild.factorId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.treeDatas,
                                                    function (item) {
                                                      return _c(
                                                        "a-tree-select-node",
                                                        {
                                                          key: item.key,
                                                          attrs: {
                                                            value: item.value,
                                                            title: item.title,
                                                          },
                                                        },
                                                        [
                                                          _vm._l(
                                                            item.children,
                                                            function (data) {
                                                              return [
                                                                _c(
                                                                  "a-tree-select-node",
                                                                  {
                                                                    key: data.key,
                                                                    attrs: {
                                                                      value:
                                                                        data.value,
                                                                      title:
                                                                        data.title,
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    data.children,
                                                                    function (
                                                                      i
                                                                    ) {
                                                                      return _c(
                                                                        "a-tree-select-node",
                                                                        {
                                                                          key: i.id,
                                                                          attrs:
                                                                            {
                                                                              value:
                                                                                i.id,
                                                                              title:
                                                                                i.title,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ]
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "35px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            `${formIndex + 1}.${
                                                              formChildIndex + 1
                                                            }`
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getFactorTitles(
                                                            formChild.factorId
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a-col",
                                          _vm._b(
                                            {},
                                            "a-col",
                                            _vm.col[1],
                                            false
                                          ),
                                          [
                                            _vm.isEdit
                                              ? _c(
                                                  "a-select",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        formItem.title ==
                                                        "急稿系数",
                                                    },
                                                    model: {
                                                      value: formChild.type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          formChild,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formChild.type",
                                                    },
                                                  },
                                                  _vm._l(
                                                    [
                                                      {
                                                        label: "文本",
                                                        value: "INPUT",
                                                      },
                                                      {
                                                        label: "数值范围",
                                                        value: "RANGE",
                                                      },
                                                    ],
                                                    function (item) {
                                                      return _c(
                                                        "a-select-option",
                                                        {
                                                          key: item.value,
                                                          attrs: {
                                                            value: item.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.label) +
                                                              "\n                "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : [
                                                  _vm._v(
                                                    _vm._s(
                                                      formChild.type == "INPUT"
                                                        ? "文本"
                                                        : "数值范围"
                                                    )
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                        _c(
                                          "a-col",
                                          _vm._b(
                                            {},
                                            "a-col",
                                            _vm.col[2],
                                            false
                                          ),
                                          [
                                            formItem.title != "急稿系数"
                                              ? _c(
                                                  "a-row",
                                                  _vm._b(
                                                    {
                                                      staticClass: "row-option",
                                                    },
                                                    "a-row",
                                                    _vm.flex,
                                                    false
                                                  ),
                                                  [
                                                    _c(
                                                      "a-col",
                                                      [
                                                        _vm.isEdit
                                                          ? _c("a-button", {
                                                              attrs: {
                                                                shape: "circle",
                                                                icon: "minus",
                                                                size: "small",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delItems(
                                                                      formIndex,
                                                                      formChildIndex
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "a-col",
                                                      [
                                                        _vm.isEdit
                                                          ? _c("a-button", {
                                                              attrs: {
                                                                shape: "circle",
                                                                icon: "plus",
                                                                size: "small",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.addItems(
                                                                      formIndex,
                                                                      formChildIndex
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._l(
                              formChild.options,
                              function (option, optionIndex) {
                                return _c(
                                  "a-form-model-item",
                                  { key: option.rowKey || optionIndex },
                                  [
                                    _c(
                                      "a-row",
                                      _vm._b(
                                        {
                                          attrs: {
                                            type: "flex",
                                            align: "middle",
                                          },
                                        },
                                        "a-row",
                                        _vm.flex,
                                        false
                                      ),
                                      [
                                        _c(
                                          "a-col",
                                          _vm._b(
                                            {},
                                            "a-col",
                                            _vm.col[0],
                                            false
                                          ),
                                          [
                                            _vm.isEdit
                                              ? _c("div", {
                                                  staticStyle: {
                                                    width: "35px",
                                                    display: "inline-block",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      optionIndex < 26
                                                        ? String.fromCharCode(
                                                            65 + optionIndex
                                                          )
                                                        : optionIndex
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.isEdit
                                              ? [
                                                  formChild.type == "RANGE"
                                                    ? _c("edit-range-select", {
                                                        staticStyle: {
                                                          width:
                                                            "calc(100% - 35px)",
                                                          float: "right",
                                                          "margin-top": "3px",
                                                        },
                                                        attrs: {
                                                          formData:
                                                            option.range,
                                                          rangeKey: "type",
                                                          valueKey: "value",
                                                          unitKey: "unit",
                                                        },
                                                      })
                                                    : _c("a-input", {
                                                        staticStyle: {
                                                          width:
                                                            "calc(100% - 35px)",
                                                          display:
                                                            "inline-block",
                                                        },
                                                        attrs: {
                                                          placeholder: "选项",
                                                          disabled:
                                                            formItem.title ==
                                                            "急稿系数",
                                                        },
                                                        model: {
                                                          value: option.title,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              option,
                                                              "title",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "option.title",
                                                        },
                                                      }),
                                                ]
                                              : [
                                                  formChild.type == "INPUT"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticStyle: {
                                                              width: "35px",
                                                            },
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  optionIndex <
                                                                    26
                                                                    ? String.fromCharCode(
                                                                        65 +
                                                                          optionIndex
                                                                      )
                                                                    : optionIndex
                                                                ),
                                                            },
                                                          }),
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                `${
                                                                  option.title ||
                                                                  ""
                                                                }`
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticStyle: {
                                                              width: "35px",
                                                            },
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  optionIndex <
                                                                    26
                                                                    ? String.fromCharCode(
                                                                        65 +
                                                                          optionIndex
                                                                      )
                                                                    : optionIndex
                                                                ),
                                                            },
                                                          }),
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                option.range
                                                                  ? _vm.getCalcRange(
                                                                      option.range
                                                                    )
                                                                  : option.range
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                ],
                                          ],
                                          2
                                        ),
                                        _c(
                                          "a-col",
                                          _vm._b(
                                            {},
                                            "a-col",
                                            _vm.col[1],
                                            false
                                          ),
                                          [
                                            _vm.isEdit
                                              ? _c("a-input-number", {
                                                  staticClass: "input-factor",
                                                  attrs: {
                                                    placeholder: "系数",
                                                  },
                                                  model: {
                                                    value: option.factor,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        option,
                                                        "factor",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "option.factor",
                                                  },
                                                })
                                              : _c("div", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      option.factor === false
                                                        ? ""
                                                        : option.factor
                                                    ),
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a-col",
                                          _vm._b(
                                            {},
                                            "a-col",
                                            _vm.col[2],
                                            false
                                          ),
                                          [
                                            formItem.title != "急稿系数"
                                              ? _c(
                                                  "a-row",
                                                  _vm._b(
                                                    {
                                                      staticClass: "row-option",
                                                    },
                                                    "a-row",
                                                    _vm.flex,
                                                    false
                                                  ),
                                                  [
                                                    _c(
                                                      "a-col",
                                                      [
                                                        _vm.isEdit
                                                          ? _c("a-button", {
                                                              attrs: {
                                                                shape: "circle",
                                                                icon: "minus",
                                                                size: "small",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delItems(
                                                                      formIndex,
                                                                      formChildIndex,
                                                                      optionIndex
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "a-col",
                                                      [
                                                        _vm.isEdit
                                                          ? _c("a-button", {
                                                              attrs: {
                                                                disabled:
                                                                  formChild
                                                                    .options
                                                                    .length >
                                                                  25,
                                                                shape: "circle",
                                                                icon: "plus",
                                                                size: "small",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.addItems(
                                                                      formIndex,
                                                                      formChildIndex,
                                                                      optionIndex
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      }
                    ),
                  ],
                  2
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.isEdit
        ? _c(
            "a-row",
            { staticStyle: { "padding-top": "20px" } },
            [
              _c(
                "a-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择规则" },
                      on: { change: _vm.ruleTypeChange },
                      model: {
                        value: _vm.ruleType,
                        callback: function ($$v) {
                          _vm.ruleType = $$v
                        },
                        expression: "ruleType",
                      },
                    },
                    _vm._l(_vm.ruleTypeList, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.value,
                          attrs: {
                            disabled: _vm.ruleList.includes(item.value),
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.label) + "\n        "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }