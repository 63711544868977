var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-form-list" },
    [
      _c(
        "a-row",
        { staticClass: "title-row", attrs: { gutter: _vm.gutter } },
        [
          _c(
            "a-col",
            _vm._b({ staticClass: "gutter-row" }, "a-col", _vm.col[0], false),
            [_c("div", { staticClass: "gutter-box" }, [_vm._v("等级")])]
          ),
          _c(
            "a-col",
            _vm._b({ staticClass: "gutter-row" }, "a-col", _vm.col[1], false),
            [_c("div", { staticClass: "gutter-box" }, [_vm._v("月平均分")])]
          ),
          _c(
            "a-col",
            _vm._b({ staticClass: "gutter-row" }, "a-col", _vm.col[2], false),
            [_c("div", { staticClass: "gutter-box" }, [_vm._v("月任务量")])]
          ),
          _c(
            "a-col",
            _vm._b({ staticClass: "gutter-row" }, "a-col", _vm.col[3], false),
            [_c("div", { staticClass: "gutter-box" }, [_vm._v("合作时长")])]
          ),
          _c(
            "a-col",
            _vm._b({ staticClass: "gutter-row" }, "a-col", _vm.col[4], false)
          ),
        ],
        1
      ),
      _vm.isEdit
        ? _vm._l(_vm.formList, function (item, idx) {
            return _c(
              "a-row",
              {
                key: idx,
                attrs: { type: "flex", align: "middle", gutter: _vm.gutter },
              },
              [
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[0],
                    false
                  ),
                  [
                    _c(
                      "a-select",
                      {
                        model: {
                          value: item.name,
                          callback: function ($$v) {
                            _vm.$set(item, "name", $$v)
                          },
                          expression: "item.name",
                        },
                      },
                      [
                        _c("a-select-option", { attrs: { value: "专家" } }, [
                          _vm._v("专家"),
                        ]),
                        _c("a-select-option", { attrs: { value: "高级" } }, [
                          _vm._v("高级"),
                        ]),
                        _c("a-select-option", { attrs: { value: "中级" } }, [
                          _vm._v("中级"),
                        ]),
                        _c("a-select-option", { attrs: { value: "低级" } }, [
                          _vm._v("低级"),
                        ]),
                        _c("a-select-option", { attrs: { value: "新人" } }, [
                          _vm._v("新人"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[1],
                    false
                  ),
                  [
                    _c("edit-range-select", {
                      attrs: {
                        "is-small": true,
                        "need-unit": false,
                        "range-key": "type",
                        "value-key": "value",
                        "unit-key": "unit",
                        "form-data": item.average || {},
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[2],
                    false
                  ),
                  [
                    _c("edit-range-select", {
                      attrs: {
                        "is-small": true,
                        "range-key": "type",
                        "value-key": "value",
                        "unit-key": "unit",
                        "form-data": item.quota || {},
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[3],
                    false
                  ),
                  [
                    _c("edit-range-select", {
                      attrs: {
                        "is-small": true,
                        "need-unit": false,
                        suffix: "月",
                        "range-key": "type",
                        "value-key": "value",
                        "unit-key": "unit",
                        "form-data": item.duration || {},
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[4],
                    false
                  ),
                  [
                    _c(
                      "div",
                      { staticClass: "row-option" },
                      [
                        _c("a-button", {
                          attrs: {
                            shape: "circle",
                            size: "small",
                            icon: "minus",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.delRow(idx)
                            },
                          },
                        }),
                        _c("a-button", {
                          attrs: {
                            shape: "circle",
                            size: "small",
                            icon: "plus",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addRow(idx)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            )
          })
        : _vm._l(_vm.formList, function (item, idx) {
            return _c(
              "a-row",
              { key: idx, attrs: { gutter: _vm.gutter } },
              [
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[0],
                    false
                  ),
                  [_vm._v(_vm._s(item.name))]
                ),
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[1],
                    false
                  ),
                  [
                    item.average
                      ? [
                          _c("edit-range-select", {
                            attrs: {
                              "range-obj": item.average,
                              "unit-value": "/话",
                            },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[2],
                    false
                  ),
                  [
                    item.quota
                      ? [
                          _c("edit-range-select", {
                            attrs: {
                              "range-obj": item.quota,
                              "need-unit-value": "",
                            },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[3],
                    false
                  ),
                  [
                    item.duration
                      ? [
                          _c("edit-range-select", {
                            attrs: { "range-obj": item.duration },
                          }),
                          _c("span", [_vm._v("月")]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }