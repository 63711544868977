<template>
  <div>
    <a-form-model-item label="类型" prop="priceCalcType">
      <template v-if="isEdit">
        <a-select v-model="formData.priceCalcType" placeholder="请选择类型">
          <a-select-option v-for="item in Object.values(priceTypeList)" :key="item.label" :value="item.value">{{
            item.label
          }}</a-select-option>
        </a-select>
      </template>
      <template v-else>{{ getLabel(priceTypeList, formData.priceCalcType) }}</template>
    </a-form-model-item>
    <template v-if="formData.priceCalcType == 'FIXED'">
      <a-form-model-item label="单价" prop="englishName">
        <template v-if="isEdit">
          <a-input v-model="formData.unitPrice" placeholder="请输入单价"></a-input>
        </template>
        <template v-else>{{ formData.unitPrice }}</template>
      </a-form-model-item>
      <a-form-model-item label="货币" prop="moneyType">
        <template v-if="isEdit">
          <a-select v-model="formData.moneyType" placeholder="请选择货币">
            <a-select-option v-for="item in Object.values(moneyTypeList)" :key="item.label" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </template>
        <template v-else>{{ getLabel(moneyTypeList, formData.moneyType) }}</template>
      </a-form-model-item>
      <a-form-model-item label="单位" prop="englishName">
        <template v-if="isEdit">
          <a-select v-model="formData.unit" placeholder="请选择单位类型">
            <a-select-option v-for="item in Object.values(unitPriceTypeList)" :key="item.label" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </template>
        <template v-else>{{ getLabel(unitPriceTypeList, formData.unit) }}</template>
      </a-form-model-item>
    </template>
    <template v-if="formData.priceCalcType == 'LEVEL'">
      <div style="display:flex;max-width: 600px;">
        <div style="width:18%">公式</div>
        <div style="width:82%">
          <template v-if="isEdit">
            <a-textarea v-model="formData.formula" placeholder="请输入公式"></a-textarea>
          </template>
          <template v-else>
            <div style="width:446px;white-space: pre-line;word-break: break-word;">{{ formData.formula }} </div>
          </template>
        </div>
      </div>
      <edit-mlevel-form :isEdit="isEdit" :formList="formData.levelRules" />
    </template>
  </div>
</template>

<script>
import { createPriceUnitTypeList } from '@/enums/unitTypeList'
import EditMlevelForm from '@/components/edit/form-list/edit-Mlevel-form.vue'
import { createPriceTypeList, createMoneyTypeList } from '@/enums/rangeTypeList'

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    EditMlevelForm
  },
  data() {
    return {
      priceType: 2,
      priceTypeList: {},
      unitPriceTypeList: {},
      moneyTypeList: {}
    }
  },
  created() {
    this.unitPriceTypeList = createPriceUnitTypeList()
    this.priceTypeList = createPriceTypeList()
    this.moneyTypeList = createMoneyTypeList()
  },
  methods: {
    getLabel(list, type) {
      let item = list[type]
      if (!item) return ''

      return item.label
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-col {
  width: 18% !important;
}
</style>
