var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-form-list" },
    [
      _vm.isEdit
        ? _vm._l(_vm.formList, function (item, idx) {
            return _c(
              "a-row",
              {
                key: idx,
                staticClass: "edit-form-row",
                attrs: { type: "flex", align: "middle", gutter: _vm.gutter },
              },
              [
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[0],
                    false
                  ),
                  [
                    _vm.needOption
                      ? [
                          _c("cascader", {
                            attrs: {
                              defaultValue: [item.type],
                              formData: item,
                              valueKey: "type",
                              options: _vm.options,
                            },
                          }),
                        ]
                      : [
                          _vm._v(
                            _vm._s(_vm.getLabel(_vm.errorTypeList, item.type))
                          ),
                        ],
                  ],
                  2
                ),
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[1],
                    false
                  ),
                  [
                    _c("input-number", {
                      attrs: { formData: item, valueKey: "score" },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row big-input-col" },
                    "a-col",
                    _vm.col[2],
                    false
                  ),
                  [
                    idx == 0
                      ? _c(
                          "div",
                          {
                            staticClass: "big-input-box",
                            style: {
                              height: `${_vm.formList.length * 47 - 15}px`,
                              "--pb": `${(_vm.formList.length - 1) * 47}px`,
                            },
                          },
                          [
                            _c("input-number", {
                              staticClass: "big-input",
                              attrs: {
                                formData: _vm.formData,
                                valueKey: _vm.maxScoreKey,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[3],
                    false
                  ),
                  [
                    _vm.needOption
                      ? _c(
                          "div",
                          { staticClass: "row-option" },
                          [
                            _c("a-button", {
                              attrs: {
                                disabled: _vm.formList.length == 1,
                                shape: "circle",
                                size: "small",
                                icon: "minus",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.delRow(idx)
                                },
                              },
                            }),
                            _c("a-button", {
                              attrs: {
                                shape: "circle",
                                size: "small",
                                icon: "plus",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addRow(idx)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            )
          })
        : _vm._l(_vm.formList, function (item, idx) {
            return _c(
              "a-row",
              { key: idx, attrs: { gutter: _vm.gutter } },
              [
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[0],
                    false
                  ),
                  [_vm._v(_vm._s(_vm.getLabel(_vm.errorTypeList, item.type)))]
                ),
                _c(
                  "a-col",
                  _vm._b(
                    { staticClass: "gutter-row" },
                    "a-col",
                    _vm.col[1],
                    false
                  ),
                  [_vm._v(_vm._s(item.score))]
                ),
                idx == 0
                  ? _c(
                      "a-col",
                      _vm._b(
                        { staticClass: "gutter-row" },
                        "a-col",
                        _vm.col[2],
                        false
                      ),
                      [_vm._v(_vm._s(_vm.formData[_vm.maxScoreKey]))]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }