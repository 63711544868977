var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-form-model-item",
        { attrs: { label: "类型", prop: "priceCalcType" } },
        [
          _vm.isEdit
            ? [
                _c(
                  "a-select",
                  {
                    attrs: { placeholder: "请选择类型" },
                    model: {
                      value: _vm.formData.priceCalcType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "priceCalcType", $$v)
                      },
                      expression: "formData.priceCalcType",
                    },
                  },
                  _vm._l(Object.values(_vm.priceTypeList), function (item) {
                    return _c(
                      "a-select-option",
                      { key: item.label, attrs: { value: item.value } },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ]
            : [
                _vm._v(
                  _vm._s(
                    _vm.getLabel(_vm.priceTypeList, _vm.formData.priceCalcType)
                  )
                ),
              ],
        ],
        2
      ),
      _vm.formData.priceCalcType == "FIXED"
        ? [
            _c(
              "a-form-model-item",
              { attrs: { label: "单价", prop: "englishName" } },
              [
                _vm.isEdit
                  ? [
                      _c("a-input", {
                        attrs: { placeholder: "请输入单价" },
                        model: {
                          value: _vm.formData.unitPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "unitPrice", $$v)
                          },
                          expression: "formData.unitPrice",
                        },
                      }),
                    ]
                  : [_vm._v(_vm._s(_vm.formData.unitPrice))],
              ],
              2
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "货币", prop: "moneyType" } },
              [
                _vm.isEdit
                  ? [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择货币" },
                          model: {
                            value: _vm.formData.moneyType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "moneyType", $$v)
                            },
                            expression: "formData.moneyType",
                          },
                        },
                        _vm._l(
                          Object.values(_vm.moneyTypeList),
                          function (item) {
                            return _c(
                              "a-select-option",
                              { key: item.label, attrs: { value: item.value } },
                              [_vm._v(_vm._s(item.label))]
                            )
                          }
                        ),
                        1
                      ),
                    ]
                  : [
                      _vm._v(
                        _vm._s(
                          _vm.getLabel(
                            _vm.moneyTypeList,
                            _vm.formData.moneyType
                          )
                        )
                      ),
                    ],
              ],
              2
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "单位", prop: "englishName" } },
              [
                _vm.isEdit
                  ? [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择单位类型" },
                          model: {
                            value: _vm.formData.unit,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "unit", $$v)
                            },
                            expression: "formData.unit",
                          },
                        },
                        _vm._l(
                          Object.values(_vm.unitPriceTypeList),
                          function (item) {
                            return _c(
                              "a-select-option",
                              { key: item.label, attrs: { value: item.value } },
                              [_vm._v(_vm._s(item.label))]
                            )
                          }
                        ),
                        1
                      ),
                    ]
                  : [
                      _vm._v(
                        _vm._s(
                          _vm.getLabel(_vm.unitPriceTypeList, _vm.formData.unit)
                        )
                      ),
                    ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm.formData.priceCalcType == "LEVEL"
        ? [
            _c(
              "div",
              { staticStyle: { display: "flex", "max-width": "600px" } },
              [
                _c("div", { staticStyle: { width: "18%" } }, [_vm._v("公式")]),
                _c(
                  "div",
                  { staticStyle: { width: "82%" } },
                  [
                    _vm.isEdit
                      ? [
                          _c("a-textarea", {
                            attrs: { placeholder: "请输入公式" },
                            model: {
                              value: _vm.formData.formula,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "formula", $$v)
                              },
                              expression: "formData.formula",
                            },
                          }),
                        ]
                      : [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "446px",
                                "white-space": "pre-line",
                                "word-break": "break-word",
                              },
                            },
                            [_vm._v(_vm._s(_vm.formData.formula) + " ")]
                          ),
                        ],
                  ],
                  2
                ),
              ]
            ),
            _c("edit-mlevel-form", {
              attrs: { isEdit: _vm.isEdit, formList: _vm.formData.levelRules },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }