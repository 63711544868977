function createModifiesTypeList() {
    return {
        LOW: {
            label: "低",
            value: "LOW",
            describe: "低"
        },
        MEDIUM: {
            label: "中",
            value: "MEDIUM",
            describe: "中"
        },
        HIGH: {
            label: "高",
            value: "HIGH",
            describe: "高"
        }
    }
}

export {
    createModifiesTypeList,
}