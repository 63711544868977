var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-coefficient-form" },
    [
      _c(
        "a-form-model",
        { attrs: { model: _vm.value } },
        _vm._l(_vm.formData.difficulties, function (formItem, formIndex) {
          return _c(
            "div",
            {
              key: formItem.rowKey || formIndex,
              staticClass: "difficulty-type",
            },
            [
              _c(
                "a-form-model-item",
                { staticClass: "type-title" },
                [
                  _c(
                    "a-row",
                    _vm._b(
                      { attrs: { type: "flex", align: "middle" } },
                      "a-row",
                      _vm.flex,
                      false
                    ),
                    [
                      _vm.isOnlyCoefficient
                        ? [
                            _c(
                              "a-col",
                              _vm._b(
                                {
                                  staticClass:
                                    "form-title-item type-title-preview",
                                },
                                "a-col",
                                _vm.calcTotalCol,
                                false
                              ),
                              [
                                _vm.isEdit
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "35px",
                                          display: "inline-block",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            `${
                                              _vm.numbers[formIndex] ||
                                              formIndex + 1
                                            }、`
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.isEdit
                                  ? _c("a-input", {
                                      staticStyle: {
                                        "font-weight": "bold",
                                        "font-size": "16px",
                                      },
                                      attrs: { placeholder: "请输入系数名称" },
                                      model: {
                                        value: formItem.title,
                                        callback: function ($$v) {
                                          _vm.$set(formItem, "title", $$v)
                                        },
                                        expression: "formItem.title",
                                      },
                                    })
                                  : _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { width: "35px" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                `${
                                                  _vm.numbers[formIndex] ||
                                                  formIndex + 1
                                                }、`
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(`${formItem.title || ""}`)
                                          ),
                                        ]),
                                      ]
                                    ),
                              ],
                              1
                            ),
                            _c("a-col", _vm._b({}, "a-col", _vm.col[2], false)),
                          ]
                        : [
                            _c(
                              "a-col",
                              {
                                staticClass:
                                  "form-title-item type-title-preview",
                                attrs: { span: 20 },
                              },
                              [
                                _vm.isEdit
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "35px",
                                          display: "inline-block",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            `${
                                              _vm.numbers[formIndex] ||
                                              formIndex + 1
                                            }、`
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.isEdit
                                  ? _c("a-input", {
                                      staticStyle: {
                                        "font-weight": "bold",
                                        "font-size": "16px",
                                      },
                                      attrs: { placeholder: "请输入系数名称" },
                                      model: {
                                        value: formItem.title,
                                        callback: function ($$v) {
                                          _vm.$set(formItem, "title", $$v)
                                        },
                                        expression: "formItem.title",
                                      },
                                    })
                                  : _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { width: "35px" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                `${
                                                  _vm.numbers[formIndex] ||
                                                  formIndex + 1
                                                }、`
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(`${formItem.title || ""}`)
                                          ),
                                        ]),
                                      ]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              _vm._b({}, "a-col", _vm.col[2], false),
                              [
                                _c(
                                  "a-row",
                                  _vm._b(
                                    { staticClass: "row-option" },
                                    "a-row",
                                    _vm.flex,
                                    false
                                  ),
                                  [_c("a-col")],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._l(formItem.categories, function (formChild, formChildIndex) {
                return _c(
                  "div",
                  {
                    key: formChild.rowKey || formChildIndex,
                    staticClass: "category-title",
                  },
                  [
                    _c(
                      "a-form-model-item",
                      [
                        _c(
                          "a-row",
                          _vm._b(
                            { attrs: { type: "flex", align: "middle" } },
                            "a-row",
                            _vm.flex,
                            false
                          ),
                          [
                            _c(
                              "a-col",
                              _vm._b(
                                { staticClass: "category-title-preview" },
                                "a-col",
                                _vm.col[0],
                                false
                              ),
                              [
                                _vm.isEdit
                                  ? _c("a-input", {
                                      attrs: { placeholder: "请输入取值目标" },
                                      model: {
                                        value: formChild.title,
                                        callback: function ($$v) {
                                          _vm.$set(formChild, "title", $$v)
                                        },
                                        expression: "formChild.title",
                                      },
                                    })
                                  : _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { width: "35px" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                `${formIndex + 1}.${
                                                  formChildIndex + 1
                                                }`
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(_vm._s(formChild.title)),
                                        ]),
                                      ]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              _vm._b({}, "a-col", _vm.col[1], false),
                              [
                                _vm.isEdit
                                  ? _c(
                                      "a-select",
                                      {
                                        model: {
                                          value: formChild.type,
                                          callback: function ($$v) {
                                            _vm.$set(formChild, "type", $$v)
                                          },
                                          expression: "formChild.type",
                                        },
                                      },
                                      _vm._l(
                                        [
                                          { label: "文本", value: "INPUT" },
                                          { label: "数值范围", value: "RANGE" },
                                        ],
                                        function (item) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: item.value,
                                              attrs: { value: item.value },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.label) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : [
                                      _vm._v(
                                        _vm._s(
                                          formChild.type == "INPUT"
                                            ? "文本"
                                            : "数值范围"
                                        )
                                      ),
                                    ],
                              ],
                              2
                            ),
                            _c(
                              "a-col",
                              _vm._b({}, "a-col", _vm.col[2], false),
                              [
                                _c(
                                  "a-row",
                                  _vm._b(
                                    { staticClass: "row-option" },
                                    "a-row",
                                    _vm.flex,
                                    false
                                  ),
                                  [
                                    _c(
                                      "a-col",
                                      [
                                        _vm.isEdit
                                          ? _c("a-button", {
                                              attrs: {
                                                shape: "circle",
                                                icon: "minus",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delItem(
                                                    formIndex,
                                                    formChildIndex
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      [
                                        _vm.isEdit
                                          ? _c("a-button", {
                                              attrs: {
                                                shape: "circle",
                                                icon: "plus",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addItem(
                                                    formIndex,
                                                    formChildIndex
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(formChild.options, function (option, optionIndex) {
                      return _c(
                        "a-form-model-item",
                        { key: option.rowKey || optionIndex },
                        [
                          _c(
                            "a-row",
                            _vm._b(
                              { attrs: { type: "flex", align: "middle" } },
                              "a-row",
                              _vm.flex,
                              false
                            ),
                            [
                              _c(
                                "a-col",
                                _vm._b({}, "a-col", _vm.col[0], false),
                                [
                                  _vm.isEdit
                                    ? _c("div", {
                                        staticStyle: {
                                          width: "35px",
                                          display: "inline-block",
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            optionIndex < 26
                                              ? String.fromCharCode(
                                                  65 + optionIndex
                                                )
                                              : optionIndex
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.isEdit
                                    ? [
                                        formChild.type == "RANGE"
                                          ? _c("edit-range-select", {
                                              staticStyle: {
                                                width: "calc(100% - 35px)",
                                                float: "right",
                                                "margin-top": "3px",
                                              },
                                              attrs: {
                                                formData: option.range,
                                                rangeKey: "type",
                                                valueKey: "value",
                                                needUnit: false,
                                                suffix: "分",
                                              },
                                            })
                                          : _c("a-input", {
                                              staticStyle: {
                                                width: "calc(100% - 35px)",
                                                display: "inline-block",
                                              },
                                              attrs: { placeholder: "选项" },
                                              model: {
                                                value: option.title,
                                                callback: function ($$v) {
                                                  _vm.$set(option, "title", $$v)
                                                },
                                                expression: "option.title",
                                              },
                                            }),
                                      ]
                                    : [
                                        formChild.type &&
                                        formChild.type != "INPUT"
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _c("div", {
                                                  staticStyle: {
                                                    width: "35px",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      optionIndex < 26
                                                        ? String.fromCharCode(
                                                            65 + optionIndex
                                                          )
                                                        : optionIndex
                                                    ),
                                                  },
                                                }),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      option.range
                                                        ? _vm.getCalcRange(
                                                            option.range
                                                          )
                                                        : option.range
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _c("div", {
                                                  staticStyle: {
                                                    width: "35px",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      optionIndex < 26
                                                        ? String.fromCharCode(
                                                            65 + optionIndex
                                                          )
                                                        : optionIndex
                                                    ),
                                                  },
                                                }),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      `${option.title || ""}`
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                      ],
                                ],
                                2
                              ),
                              _c(
                                "a-col",
                                _vm._b({}, "a-col", _vm.col[1], false),
                                [
                                  _vm.isEdit
                                    ? _c("a-input-number", {
                                        staticClass: "input-factor",
                                        attrs: { placeholder: "系数" },
                                        model: {
                                          value: option.factor,
                                          callback: function ($$v) {
                                            _vm.$set(option, "factor", $$v)
                                          },
                                          expression: "option.factor",
                                        },
                                      })
                                    : _c("div", {
                                        domProps: {
                                          textContent: _vm._s(
                                            option.factor === false
                                              ? ""
                                              : option.factor
                                          ),
                                        },
                                      }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                _vm._b({}, "a-col", _vm.col[2], false),
                                [
                                  _c(
                                    "a-row",
                                    _vm._b(
                                      { staticClass: "row-option" },
                                      "a-row",
                                      _vm.flex,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-col",
                                        [
                                          _vm.isEdit
                                            ? _c("a-button", {
                                                attrs: {
                                                  shape: "circle",
                                                  icon: "minus",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delItem(
                                                      formIndex,
                                                      formChildIndex,
                                                      optionIndex
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        [
                                          _vm.isEdit
                                            ? _c("a-button", {
                                                attrs: {
                                                  disabled:
                                                    formChild.options.length >
                                                    25,
                                                  shape: "circle",
                                                  icon: "plus",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addItem(
                                                      formIndex,
                                                      formChildIndex,
                                                      optionIndex
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }