<template>
    <a-cascader v-model="defaultValue" :options="options" placeholder change-on-select @change="onChange" />
</template>
<script>
export default {
    props: {
        options: {
            type: Array,
            default: () => []
        },
        formData: {
            type: Object,
            default: () => ({})
        },
        valueKey: {
            type: String,
            default: "type"
        },
        defaultValue: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {};
    },
    methods: {
        onChange(value) {
            // 直接取数组第一个元素（因为只有一层
            this.formData[this.valueKey] = value[0];
        }
    }
};
</script>