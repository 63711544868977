var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-cascader", {
    attrs: { options: _vm.options, placeholder: "", "change-on-select": "" },
    on: { change: _vm.onChange },
    model: {
      value: _vm.defaultValue,
      callback: function ($$v) {
        _vm.defaultValue = $$v
      },
      expression: "defaultValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }