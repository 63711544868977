<template>
  <!-- 等级规则-可编辑表单列表 -->
  <div class="edit-form-list">
    <a-row class="title-row" :gutter="gutter">
      <a-col class="gutter-row" v-bind="col[0]">
        <div class="gutter-box">等级</div>
      </a-col>
      <a-col class="gutter-row" v-bind="col[1]">
        <div class="gutter-box">月平均分</div>
      </a-col>
      <a-col class="gutter-row" v-bind="col[2]">
        <div class="gutter-box">月任务量</div>
      </a-col>
      <a-col class="gutter-row" v-bind="col[3]">
        <div class="gutter-box">合作时长</div>
      </a-col>
<!--       <a-col class="gutter-row" v-bind="col[4]">
        <div class="gutter-box">单价基数</div>
      </a-col> -->
      <a-col class="gutter-row" v-bind="col[4]"></a-col>
    </a-row>
    <template v-if="isEdit">
      <a-row type="flex" align="middle" :gutter="gutter" v-for="(item, idx) in formList" :key="idx">
        <a-col class="gutter-row" v-bind="col[0]">
          <!-- <a-input v-model="item.name" /> -->
          <a-select v-model="item.name">
            <a-select-option value="专家">专家</a-select-option>
            <a-select-option value="高级">高级</a-select-option>
            <a-select-option value="中级">中级</a-select-option>
            <a-select-option value="低级">低级</a-select-option>
            <a-select-option value="新人">新人</a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" v-bind="col[1]">
          <edit-range-select :is-small="true" :need-unit="false" :range-key="'type'" :value-key="'value'"
            :unit-key="'unit'" :form-data="item.average || {}" />
        </a-col>
        <a-col class="gutter-row" v-bind="col[2]">
          <edit-range-select :is-small="true" :range-key="'type'" :value-key="'value'" :unit-key="'unit'"
            :form-data="item.quota || {}" />
        </a-col>
        <a-col class="gutter-row" v-bind="col[3]">
          <edit-range-select :is-small="true" :need-unit="false" :suffix="'月'" :range-key="'type'" :value-key="'value'"
            :unit-key="'unit'" :form-data="item.duration || {}" />
        </a-col>
<!--         <a-col class="gutter-row" v-bind="col[4]">
          <edit-range-select is-small need-unit :need-range="false" unit-type="price" value-key="value" unit-key="unit"
            :form-data="item.score || {}" />
        </a-col> -->
        <a-col class="gutter-row" v-bind="col[4]">
          <div class="row-option">
            <a-button shape="circle" :size="'small'" icon="minus" @click="delRow(idx)" />
            <a-button shape="circle" :size="'small'" icon="plus" @click="addRow(idx)" />
          </div>
        </a-col>
      </a-row>
    </template>
    <template v-else>
      <a-row :gutter="gutter" v-for="(item, idx) in formList" :key="idx">
        <!-- 等级 -->
        <a-col class="gutter-row" v-bind="col[0]">{{ item.name }}</a-col>
        <!-- 月平均分 -->
        <a-col class="gutter-row" v-bind="col[1]">
          <template v-if="item.average">
            <edit-range-select :range-obj="item.average" unit-value="/话" />
          </template>
        </a-col>
        <!-- 月任务量 -->
        <a-col class="gutter-row" v-bind="col[2]">
          <template v-if="item.quota">
            <edit-range-select :range-obj="item.quota" need-unit-value />
          </template>
        </a-col>
        <!-- 合作时长 -->
        <a-col class="gutter-row" v-bind="col[3]">
          <template v-if="item.duration">
            <edit-range-select :range-obj="item.duration" />
            <span>月</span>
          </template>
        </a-col>
        <!-- 单价基数 -->
        <!-- <a-col class="gutter-row" v-bind="col[4]">
          <edit-range-select :range-obj="item.score" :need-range="false" need-unit-value unit-type="price"
            :suffix="item.quota.unitLabel ? '/' + item.quota.unitLabel : ''" />
        </a-col> -->
      </a-row>
    </template>
  </div>
</template>

<script>
import EditRangeSelect from '@/components/edit/edit-range-select.vue'
import { createRangeTypeList, createPriceTypeList } from '@/enums/rangeTypeList'
import { createUnitTypeList } from '@/enums/unitTypeList'

export default {
  name: 'edit-level-form',
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    formList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    EditRangeSelect,
  },
  data() {
    return {
      rangeTypeList: {},
      priceTypeList: {},
      unitTypeList: {},
      gutter: 16,
      col: [{ span: 4 }, { span: 5 }, { span: 5 }, { span: 5 }, { span: 4 }, { span: 3 }],
    }
  },
  created() {
    this.rangeTypeList = createRangeTypeList()
    this.priceTypeList = createPriceTypeList()
    this.unitTypeList = createUnitTypeList()
  },
  mounted() {
    if (!this.formList.length) {
      this.formList.push(this.createRow())
    }
  },
  methods: {
    delRow(index) {
      // 删除需要加上二次确认框，并请求删除接口
      if (this.formList.length == 1) {
        this.formList.splice(index, 1, this.createRow())
      } else {
        this.formList.splice(index, 1)
      }
    },
    addRow(index) {
      this.formList.splice(index + 1, 0, this.createRow())
    },
    createRow() {
      return {
        ruleId: null,
        name: '',
        month: '',
        scoreId: null,
        score: {
          type: null,
          unit: 'CNY',
          value: null,
          start: null,
          end: null,
          id: null,
        },
        average: {
          type: 'GT',
          unit: 'CHAPTER',
          value: null,
          start: null,
          end: null,
          id: null,
        },
        quotaId: null,
        durationId: null,
        quota: {
          type: 'GT',
          unit: 'CHAPTER',
          value: null,
          start: null,
          end: null,
          id: null,
          unitLabel: '',
        },
        duration: {
          type: 'GT',
          unit: 'EACH',
          value: null,
          start: null,
          end: null,
          id: null,
        },
        id: null,
      }
    },
  },
}
</script>

<style scoped lang="less">
.edit-form-list {
  padding: 10px 0;

  .ant-row,
  .ant-row-flex {
    padding: 15px 0 0 0;

    /deep/ .ant-input-affix-wrapper {
      .ant-input-prefix {
        left: 8px;
      }

      .ant-input {
        padding-left: 24px;
      }
    }
  }

  .title-row {
    font-weight: bold;
  }

  .row-option {
    display: flex;
    justify-content: flex-end;

    button {
      &:last-child {
        margin-left: 6px;
      }
    }
  }
}
</style>