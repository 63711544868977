function createErrorTypeList() {
    return {
        ERROR: {
            label: "错翻数",
            value: "ERROR",
            describe: "错翻"
        },
        SUPER_HARD: {
            label: "超级硬伤数",
            value: "SUPER_HARD",
            describe: "超级硬伤"
        },
        HARD: {
            label: "硬伤数",
            value: "HARD",
            describe: "硬伤"
        },
        V1_HARD: {
            label: "1改硬伤数",
            value: "V1_HARD",
            describe: "1改硬伤"
        },
        V2_HARD: {
            label: "2改硬伤数",
            value: "V2_HARD",
            describe: "2改硬伤"
        },
        MORE_THAN_V2_HARD: {
            label: "2改以上硬伤数",
            value: "MORE_THAN_V2_HARD",
            describe: "2改以上硬伤"
        },
        SUPER_SOFT: {
            label: "超级软伤数",
            value: "SUPER_SOFT",
            describe: "超级软伤"
        },
        SOFT: {
            label: "软伤数",
            value: "SOFT",
            describe: "软伤"
        },
    }
}

function createHardInjuryTypeTree() {
    let list = createErrorTypeList()
    let options = {}
    options.SUPER_HARD = { ...list.SUPER_HARD }
    options.HARD = { ...list.HARD }
    options.V1_HARD = { ...list.V1_HARD }
    options.V2_HARD = { ...list.V2_HARD }
    options.MORE_THAN_V2_HARD = { ...list.MORE_THAN_V2_HARD }

    return options
}

function createSoftInjuryTypeTree() {
    let list = createErrorTypeList()
    let options = {}
    options.SUPER_SOFT = { ...list.SUPER_SOFT }
    options.SOFT = { ...list.SOFT }
    return options
}

function createErrorTypeTree() {
    let list = createErrorTypeList()
    return {
        ERROR: list.ERROR
    }
}

export {
    createErrorTypeList,
    createHardInjuryTypeTree,
    createSoftInjuryTypeTree,
    createErrorTypeTree,
}