function createDeliveriesTypeList() {
    return {
        ADVANCE: {
            label: "提前",
            value: "ADVANCE",
            describe: "提前"
        },
        ON_TIME: {
            label: "按时",
            value: "ON_TIME",
            describe: "按时"
        },
        DELAY: {
            label: "拖稿",
            value: "DELAY",
            describe: "拖稿"
        }
    }
}

export {
    createDeliveriesTypeList,
}